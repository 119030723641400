export const links = [
  {
    to: 'nav',
  },
  {
    to: 'aboutUs',
    name: 'About Me',
  },
  {
    to: 'services',
    name: 'Services',
  },

  {
    to: 'resources',
    name: 'Resources',
  },
  {
    to: 'contactUs',
    name: 'Contact Me',
  },

];
